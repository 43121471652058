var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "progress-bar"
    }
  }, [_vm.processValue ? _c('b-progress', {
    staticClass: "progress-bar-info mb-50 mt-25",
    attrs: {
      "value": _vm.processValue,
      "animated": "",
      "variant": "info",
      "height": "3px"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }