<template>
  <div id="progress-bar">
    <b-progress
      v-if="processValue"
      :value="processValue"
      animated
      variant="info"
      class="progress-bar-info mb-50 mt-25"
      height="3px"
    />
  </div>
</template>

<script>
import { BProgress } from 'bootstrap-vue'

import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    BProgress,
  },
  setup() {
    const { processValue } = useBookingHandle()
    return {
      processValue,
    }
  },
}
</script>
<style lang="scss" scoped>
#progress-bar ::v-deep {
  position: absolute;
  right: 1rem;
  left: 1rem;
  top: -10px;
  z-index: 10;
}
</style>
